<template>
  <div class="page-section h-100">
    <div v-if="formOpen != true" class="change-mode" id="modifIdentityInfo">
      <div v-on:click="openForm()">
        <i class="fas fa-pencil-alt"></i>
        <span class="change-tooltip">{{ $t("_60") }}</span>
      </div>
    </div>

    <h2>{{ txt197 }}</h2>

    <form enctype="multipart/form-data" class="bg-white rounded-lg" method="post" action="/ws/update_patient.php"
      @submit.prevent="submit">
      <div class="row">
        <div class="col-md-6">
          <h3 class="title_fiche_patient">{{ $t("_61") }}</h3>
          <InputGroup>
            <Input v-model="user.libelle_patient" :label="$t('_230')" name="patient_id" readonly required />

            <Input v-model="form.name" :label="$t('_140')" name="name" :readonly="formOpen === false" required />

            <Input v-model="form.forname" :label="$t('_141')" name="forname" :readonly="formOpen === false" required accept-charset="utf-8" />


          </InputGroup>
        </div>
        <!-- QR CODE -->
        <div class="col-md-6">
          <img :src="qrcode" alt="QR Code" />
        </div>
        <!-- END QR CODE -->
      </div>
      <div class="row">
        <div class="col-md-6">
          <InputGroup :label="$t('_142')" required>
            <Radio v-model="form.gender" :value="1" name="gender" :disabled="formOpen === false"
              :readonly="formOpen === false" id="gender1" :label="$t('_143')" />

            <Radio v-model="form.gender" :value="2" name="gender" :disabled="formOpen === false"
              :readonly="formOpen === false" id="gender2" :label="$t('_144')" />
          </InputGroup>
        </div>
        <div class="col-md-6">
          <Input v-model="form.birthdate" type="date" :label="$t('_145')" :disabled="formOpen === false"
            :readonly="formOpen === false" name="birthdate" min="1900-01-01" :max="$moment().format('YYYY-MM-DD')"
            required />
        </div>
      </div>

      <fieldset v-if="formOpen == true" :disabled="formOpen === false">
        <Submit class="text-center" :label="$t('_146')" />
      </fieldset>
    </form>
  </div>
</template>

<script>

import InputGroup from '../inputs/InputGroup';
import Input from '../inputs/Input';
import Radio from '../inputs/Radio';
import Autocomplete from '../inputs/Autocomplete';
import Checkbox from '../inputs/Checkbox';
import FileUpload from '../inputs/FileUpload';
import Submit from '../inputs/Submit';
import { toFormData } from '../../helpers';

export default {
  name: "InfoPatient",

  components: {
    InputGroup,
    Input,
    Radio,
    Autocomplete,
    Checkbox,
    FileUpload,
    Submit,
  },

  props: {
    context: {
      type: String,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },

    uuid: {
      type: String,
      required: true,
    },
    editModeActivated: {
      type: Function,
    },
    qrcode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      formOpen: false,
      txt197: '',
      form: {
        form: 'form_info_patient',
        patient_id: this.user.patient_id,
        forname: this.decodeHtmlEntities(this.user.forname),
        name: this.decodeHtmlEntities(this.user.name),
        gender: this.user.gender,
        birthdate: this.user.birthdate,
      },
    }
  },

  computed: {
    prefix() {
      return this.context === 'hcp' ? '/professionnel' : '/cro';
    },
  },
  mounted() {
    console.log(this.user.forname);
    console.log(this.decodeHtmlEntities(this.user.forname));
  },

  created() {
    var age = this.userAge();
    this.txt197 = this.$t('_197', { user_name: this.decodeHtmlEntities(this.user.name), user_forname: this.decodeHtmlEntities(this.user.forname), age: age });
  },

  methods: {
    openForm: function () {
      this.formOpen = true
      this.editModeActivated()
    },

    userAge() {
      const today = new Date();
      const birthdate = new Date(this.form.birthdate);

      let age = today.getFullYear() - birthdate.getFullYear();
      const m = today.getMonth() - birthdate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
        age = age - 1;
      }

      return age;
    },

    submit(event) {
      this.loading = true;
      this.$axios.post(event.target.action, toFormData(this.form))
        .then(response => {
          this.loading = false;
          this.formOpen = false;
          this.editModeActivated()
        })
        .catch(e => {
          const errors = e.response.data;
          console.log("une erreur est survenue dans le fichier InfoPatient.vue " + errors);
        });
    },
    cleanUtf8Input(value) {
      return decodeURIComponent(encodeURIComponent(value));
    },

    decodeHtmlEntities(html) {
      const txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }

  },

  watch: {
    'form.birthdate': {
      handler: function (val, oldVal) {
        var age = this.userAge();
        this.txt197 = this.$t('_197', { user_name: this.user.name, user_forname: this.user.forname, age: age });
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
.title_fiche_patient {
  font-weight: bolder;
  color: rgba(77, 77, 77, 0.87);
}
</style>
