<template>
  <div v-if="isOpen === true">

    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="row w-100">
            <div class="col-md-9">
              <h2>
                Rejoignez le programme Diab eCare offrant à vos patients inscrits des solutions et services personnalisés avec :
              </h2>
              <p>
                <ul>
                <li>
                  Intégration d'un carnet glycémique digital comme nouveau service de monitoring, avec l'amélioration du suivi à travers des graphiques spécifiques.
                </li>
                <li>
                  Intégration d'un service de continuous glucose monitoring pour une surveillance en temps réel.
                </li>
                <li>
                  Différentiation du contenu éducatif pour les patients naïfs à l'insuline pour un meilleur impact
                </li>
                <li>
                  Ajout de nouveaux canaux d'éducation thérapeutique pour une meilleure expérience d'apprentissage (articles web, podcasts, brochures)
                </li>
              </ul>
              </p>
              <p>
                Nous vous invitons à consulter les conditions générales d'utilisation du programme support patient Diab eCare et à y adhérer en cliquant sur la case à cocher ci-dessous :
              </p>
            </div>
            <div class="col-md-3">
              <div class="row d-flex flex-column">
                <img src="/img/DiabeCare_logoH__1_.png" alt="" class="logo1">
                <img src="/img/carnet_glycémique.jpg" alt="" class="logo2">
              </div>
            </div>
          </div>
          <div class="row w-100">
            <div class="input input-colapsed" style="max-width: initial; margin:auto">
              <input type="checkbox" name="agreementDiabete" id="inputCheckboxAgreementDiabete" class="input-body" value="1">
              <label for="inputCheckboxAgreementDiabete" class="input-label"> J'accepte les <a href="/documents/cgu_professionnel_new.pdf" target="_blank"> Conditions Générales d'utilisations Programmes Support Patients </a> et confirme mon inscription au programme Diab eCare.</label>
            </div>
          </div>
          <div class="button_modal mt-3">
            <a href="/commun/disconnect.php?redirect=/professionnel/" class="btn">	x Fermer</a>
            <a @click="validCgu()" class="btn">	&#10004; Valider</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalCgu",

  props: {
    hcp: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  mounted() {
    if (!this.hcp.has_valid_cgu_diabete || !this.hcp.has_valid_cgu) {
      this.isOpen = true;
    }
  },

  methods: {
    closeModal: function () {
      this.isOpen = false;
    },
    validCgu : function(){
      const endpoint = '/professionnel/ws/valid_cgu_pds.php';
      const hcp_id = this.hcp.uuid;
      let isChecked = false;
      if ($('#inputCheckboxAgreementDiabete').prop('checked')) {
        isChecked = true;
      }else{
        showAlert('Erreur', 'Veuillez lire et valider les CGUs avant de poursuivre.');
        return;
      }

      this.$axios.get(`${endpoint}?uuid=${hcp_id}&isChecked=${isChecked}`)
        .then(response => {
          let data = response.data.data;
          if (data) {
            this.closeModal();
          }
        })
        .catch(e => {
            console.error(e);
        });
    },
    // getHcpInfo : function(){
    //   const endpoint = '/professionnel/ws/get_infos_pds.php';
    //   const hcp_id = sessionStorage.getItem('uuid');

    //   this.$axios.get(`${endpoint}?uuid=${hcp_id}`)
    //     .then(response => {
    //       let data = response.data.data;
    //       console.log(data);
    //     })
    //     .catch(e => {
    //         console.error(e);
    //     });
    // }
  },
}
</script>

<style scoped>
.logo1, .logo2{
  width: 100%;
  margin-top: 55px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 40%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.button_close_modal {
  display: flex;
  justify-content: flex-end;
}

.button_close_modal a {
  text-decoration: none;

}

.button_modal {
  display: flex;
  justify-content: space-between;
}

</style>
