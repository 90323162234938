<template>
  <fieldset :disabled="loading">
    <legend>
      <h2 class="mb-0">{{ $t("_83") }}</h2>
    </legend>

    <InputGroup :label="$t('_147')" required>
      <Checkbox
        v-model="form.diabetes.active"
        name="hasDiabetes"
        id="conditionDiabetes"
        :label="$t('_148')"
        :disabled="!CanInsertPatientCroOrHcp || (canView.diabeteData != '' && canView.diabeteData) || (notMatch.diabete != '' && notMatch.diabete)"
        @change="disableDefaultSolution(5)"
        />

      <Checkbox
        v-model="form.hypertension.active"
        name="hasHypertension"
        id="conditionHypertension"
        :label="$t('_149')"
        :disabled="context === 'cro' || !hcp.has_hta || (canView.htaData != '' && canView.htaData) || (notMatch.hta != '' && notMatch.hta) || isPatientMinor"
        @change="disableDefaultSolution(7)"
        />
    </InputGroup>

    <div  v-if="form.diabetes.active || (canView.diabeteData != '' && canView.diabeteData)">
      <span>
        <div>
          <legend>
            <h2 class="mb-0" :class="{ 'disabled-text': !form.diabetes.active}" v-if="notMatch.diabete == ''">Profil médical Diabète</h2>
            <h2 class="mb-0 disabled-text" v-if="notMatch.diabete != '' && notMatch.diabete">
              Profil médical Diabète : Ce profil médical a déjà été rempli par l'un de vos confrères. Pour des questions de sécurité, vous ne pouvez pas le consulter.
            </h2>
          </legend>      
        </div>
      </span>
      <hr>
      <div v-if="form.diabetes.active || (canView.diabeteData != '' && canView.diabeteData)">
      <!-- Profil médical Diabète -->
        <div>

            <Input
                v-model="form.diabetes.code"
                :label="$t('_166')"
                name="hcpCode"
                :readonly="context === 'hcp'"
                :custom-margin='true'
                required
            />

          <InputGroup :label="$t('_150')" required>
            <Radio
              v-model="form.diabetes.type"
              :value="1"
              name="diabetesType"
              id="diabetesType1"
              :label="$t('_151')"
              @change="preChecked"
              :disabled="(canView.diabeteData != '' && canView.diabeteData)"
            />

            <Radio
              v-model="form.diabetes.type"
              :value="2"
              name="diabetesType"
              id="diabetesType2"
              :label="$t('_152')"
              @change="preChecked"
              :disabled="(canView.diabeteData != '' && canView.diabeteData)"
            />

            <Radio
              v-model="form.diabetes.type"
              :value="3"
              name="diabetesType"
              id="diabetesType3"
              :label="$t('_153')"
              @change="preChecked"
              :disabled="(canView.diabeteData != '' && canView.diabeteData)"
            />
          </InputGroup>

          <InputGroup :label="$t('_154')" required>
            <Checkbox
              v-model="form.diabetes.treatments"
              :value="1"
              name="diabetesTreatments[]"
              id="diabetesTreatment1"
              :label="$t('_155')"
              :disabled="form.diabetes.treatments.includes(3) || (canView.diabeteData != '' && canView.diabeteData)"
              :clear="form.diabetes.treatments.includes(3)"
              @change="preChecked"
            />
  <!-- 156 -->
            <Checkbox
              v-model="form.diabetes.treatments"
              :value="2"
              name="diabetesTreatments[]"
              id="diabetesTreatment2"
              :label="$t('_156')"
              :disabled="canView.diabeteData != '' && canView.diabeteData"
              @change="uncheckProgram"
            />

            <!-- TODO disabled for now
              <Checkbox
                  v-model="form.diabetes.treatments"
                  :value="treatments['Toujeo']"
                  name="diabetesTreatments[]"
                  id="diabetesTreatment3"
                  :label="$t('_157')"
                  :disabled="form.diabetes.treatments.includes(1)"
                  :clear="form.diabetes.treatments.includes(1)"
            />-->
            <Checkbox
              v-model="form.diabetes.treatments"
              :value="3"
              name="diabetesTreatments[]"
              id="diabetesTreatment3"
              :label="$t('_157')"
              :disabled="!false"
              :clear="!false"
            />
          </InputGroup>

          <InputGroup :label="$t('_158')" required>
            <Radio
              v-model="form.diabetes.insulinLessThan3Months"
              value="1"
              name="insulinLessThan3Months"
              id="insulinTrue"
              :label="$t('_159')"
              @change="preChecked"
              :disabled="(canView.diabeteData != '' && canView.diabeteData)"
            />

            <Radio
              v-model="form.diabetes.insulinLessThan3Months"
              value="0"
              name="insulinLessThan3Months"
              id="insulinFalse"
              :label="$t('_160')"
              @change="preChecked"
              :disabled="(canView.diabeteData != '' && canView.diabeteData)"
            />
          </InputGroup>
        </div>
        <!--start solutions-->
        <legend>
          <h2 class="mb-0">{{ $t("_98") }}</h2>
        </legend>
        <div>
          <div class="row">
            <div class="col-12 asteriskRequired">{{ $t("_215") }}</div>
            <div class="col-md-6 col-lg-4" v-if="getSolutionByGroup('has_diabete',1).length > 0">
              <h3 class="label-multisolutions solution-education">
                {{ $t("_99") }}
              </h3>

              <!-- Education diabetes solutions -->
              <InputGroup required>
                <Checkbox
                  v-for="solution in getSolutionByGroup('has_diabete',1)"
                  :key="`educationSolution${solution.id}`"
                  v-model="form.solutions"
                  :value="solution.id"
                  name="educationSolutions"
                  :tooltip="helperTooltip(solution.id)"
                  :id="`monitoringSolution${solution.id}`"
                  :label="solution.name_fr_FR"
                  :disabled="isDisabledSolution(solution.id)"
                  :clear="isDisabledSolution(solution.id)"
                />
                <!-- End Education diabetes solutions -->
              </InputGroup>
            </div>

            <div class="col-md-6 col-lg-4" v-if="getSolutionByGroup('has_diabete',2).length > 0">
              <h3 class="label-multisolutions solution-monitoring">
                {{ $t("_100") }}
              </h3>
              <InputGroup>
                <!-- Monitoring diabetes solutions -->
                <Checkbox
                  v-for="solution in getSolutionByGroup('has_diabete',2)"
                  :key="`monitoringSolution${solution.id}`"
                  v-model="form.solutions"
                  :value="solution.id"
                  name="monitoringSolutions[]"
                  :tooltip="helperTooltip(solution.id)"
                  :id="`monitoringSolution${solution.id}`"
                  :label="solution.name_fr_FR"
                  :disabled="isDisabledSolution(solution.id)"
                  :clear="isDisabledSolution(solution.id)"
                />
                <!-- End Monitoring diabetes solutions -->
              </InputGroup>
              <!--end solutions-->
            </div>
          </div>
        </div>
        <!-- Diabete Program--> 
        <InputGroup
          :label="$t('_161')"
          required
        >
          <Checkbox
            v-model="form.diabetes.programs"
            :value="1"
            name="diabetesPrograms[]"
            id="diabetesProgram1"
            :label="$t('_162')"
            :tooltip="$t('tooltip_diab_ecare')"
            :disabled="!form.diabetes.active"
            :clear="!form.diabetes.active"
            @change="$emit('programChange')"
          />

        </InputGroup>
      </div>
    </div>
    <!-- End profil médical Diabète -->
    <div v-if="form.hypertension.active">
      <span>
        <div>
          <legend>
            <h2 class="mb-0" :class="{ 'disabled-text': !form.hypertension.active}" v-if="notMatch.hta == '' && !isPatientMinor && context === 'hcp'">Profil médical Hypertension</h2>
            <h2 class="mb-0 disabled-text" v-if="(notMatch.hta != '' && notMatch.hta) && context === 'hcp'">
              Profil médical Hypertension : Ce profil médical a déjà été rempli par l'un de vos confrères. Pour des questions de sécurité, vous ne pouvez pas le consulter.
            </h2>
            <h2 class="mb-0 disabled-text" v-if="isPatientMinor && context === 'hcp'">
              Profil médical Hypertension : Le patient doit avoir 18 ans et plus, merci de revoir sa date de naissance pour poursuivre l'inscription.
            </h2>
            <h2 class="mb-0 disabled-text" v-if="context === 'cro' && !canView.htaData">
              Profil médical Hypertension : Vous n'avez pas les droits pour inscrire le patient au programme support patient HTA eCare.
            </h2>
            <h2 class="mb-0 disabled-text" v-if="context === 'cro' && canView.htaData != '' && canView.htaData">
              Profil médical Hypertension : Ce profil médical a déjà été rempli par un professionnel de santé. Pour des questions de sécurité, vous ne pouvez pas le consulter.
            </h2>
          </legend>      
        </div>
      </span>
      <hr>
      <!-- start profil médical Hypertension-->
      <div v-if="form.hypertension.active">
        <div  id="hypertension">
          <Input
                v-model="form.hypertension.code"
                :label="$t('_166')"
                name="hcpCode"
                :readonly="context === 'hcp'"
                :custom-margin='true'
                required
            />

          <InputGroup label="Tabagisme" required>
            <Radio
              v-model="form.hypertension.data.smoking"
              value="1"
              name="smoking"
              id="smoking1"
              label="Oui"
            />

            <Radio
              v-model="form.hypertension.data.smoking"
              value="0"
              name="smoking"
              id="smoking0"
              label="Non"
            />
          </InputGroup>

          <InputGroup
          v-if="true"
          :label="$t('_hypertension_treatments') + ' :'"
          required
          >
            <Checkbox
              v-model="form.hypertension.treatments"
              :value="4"
              name="hypertensionTreatments[]"
              id="hypertensionTreatment1"
              label="APROVEL ®"
            />

            <Checkbox
              v-model="form.hypertension.treatments"
              :value="6"
              name="hypertensionTreatments[]"
              id="hypertensionTreatment2"
              label="APROVASC ®"
            />

            <Checkbox
              v-model="form.hypertension.treatments"
              :value="9"
              name="hypertensionTreatments[]"
              id="hypertensionTreatment3"
              label="CO-APROVEL ®"
            />

            <Checkbox
              v-model="form.hypertension.treatments"
              :value="12"
              name="hypertensionTreatments[]"
              id="hypertensionTreatment4"
              label="TRIATEC ®"
            />

            <Checkbox
              v-model="form.hypertension.treatments"
              :value="16"
              name="hypertensionTreatments[]"
              id="hypertensionTreatment5"
              label="TRITAZIDE ®"
            />
          </InputGroup>
          
          <InputGroup
          v-if="true"
          :label="$t('_antihypertensive_less_than_6_months') + ' :'"
          required
          >
            <Radio
              v-model="form.hypertension.data.antihypertensive_less_than_6_months"
              value="1"
              name="antihypertensveLessThan6Months"
              id="antihypertensiveTrue"
              :label="$t('_159')"
            />

            <Radio
              v-model="form.hypertension.data.antihypertensive_less_than_6_months"
              value="0"
              name="antihypertensveLessThan6Months"
              id="antihypertensiveFalse"
              :label="$t('_160')"
            />
          </InputGroup>

          <InputGroup
          :label="$t('_161')"
          required
          >
            <Checkbox
              v-model="form.hypertension.programs"
              :value="3"
              name="hypertensionPrograms[]"
              id="hypertensionProgram1"
              :tooltip="$t('tooltip_hta_psp_phone_email')"
              :label="$t('_222')"
            />
          </InputGroup>

          <!--Program support patient-->
          <!-- Hypertension solutions -->
          <legend>
            <h2 class="mb-0">{{ $t("_98") }}</h2>
          </legend>

          <div class="row">
            <div class="col-12 asteriskRequired">
              {{ $t("_multi_solutions_hta") }}
            </div>

            <div class="col-md-6 col-lg-4" v-if="getSolutionByGroup('has_hta',1).length > 0">
              <h3 class="label-multisolutions solution-education">
                {{ $t("_99") }}
              </h3>

              <InputGroup required>
                <!-- Education hypertension solutions -->
                <Checkbox
                  v-for="solution in getSolutionByGroup('has_hta',1)"
                  :key="`educationSolution${solution.id}`"
                  v-model="form.solutions"
                  :value="solution.id"
                  name="educationSolutions[]"
                  :tooltip="helperTooltip(solution.id)"
                  :id="`monitoringSolution${solution.id}`"
                  :label="solution.name_fr_FR"
                  :disabled="isDisabledSolution(solution.id)"
                  :clear="isDisabledSolution(solution.id)"
                />
                <!-- End Education hypertension solutions -->
              </InputGroup>
            </div>
            <div class="col-md-6 col-lg-4" v-if="getSolutionByGroup('has_hta',2).length > 0">
              <h3 class="label-multisolutions solution-monitoring">
                {{ $t("_100") }}
              </h3>
              <InputGroup>
                <!-- Monitoring hypertension solutions -->
                <Checkbox
                  v-for="solution in getSolutionByGroup('has_hta',2)"
                  :key="`monitoringSolution${solution.id}`"
                  v-model="form.solutions"
                  :value="solution.id"
                  name="monitoringSolutions[]"
                  :tooltip="helperTooltip(solution.id)"
                  :id="`monitoringSolution${solution.id}`"
                  :label="solution.name_fr_FR"
                  :disabled="isDisabledSolution(solution.id)"
                  :clear="isDisabledSolution(solution.id)"
                />
                <!-- End Monitoring hypertension solutions -->
              </InputGroup>
            </div>
          </div> 
          <!-- End Hypertension solutions -->
        </div>
      </div>
    </div>
    <!-- end profil médical Hypertension-->
  </fieldset>
</template>

<script>
import InputGroup from "@components/inputs/InputGroup";
import Input from "@components/inputs/Input";
import Radio from "@components/inputs/Radio";
import Checkbox from "@components/inputs/Checkbox";
import Submit from "@components/inputs/Submit";

export default {
  components: {
    InputGroup,
    Input,
    Radio,
    Checkbox,
    Submit
  },

  props: {
    form: {
      type: Object,
      required: true
    },
    context: {
      type: String,
      required: true,
    },
    rulesData: {
      type: Object,
      required: true
    },
    treatments: {
      type: Object,
      required: true
    },
    hcp: {
      type: Object,
      required: false
    },
    isPatientMinor: {
      type: Boolean,
      required: false
    },
    canView: {
      type: Object,
      required: false
    },
    notMatch: {
      type: Object,
      required: false
    },
    programs: {
      type: [Object, Array],
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    },

    edit: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      diabetesVirtualStarterKit: true,
      diabetesDiabeduc: true,
      diabetesMyDoseCoach: true,
      diabetesDigitalLogbook: true,
      diabetesContinuousGlucoseMonitoring: true,
      htaUpdate:false,
      diabeteUpdate:false,
      url: window.location.pathname,
      solutionList:[],
    };
  },

  computed: {
    treatmentsForHypertension: function() {
      let excluded = ["Lantus", "Apidra", "Toujeo"];

      let objectToReturn = {};

      Object.keys(this.treatments).forEach(key => {
        if (!excluded.includes(key)) {
          objectToReturn[key] = this.treatments[key];
        }
      });

      return objectToReturn;
    },
    isHtaUpdate(){
      this.htaUpdate = sessionStorage.getItem('fds') == 'hta' ? true : false;
      if (this.url === '/professionnel/patient_medical_form.php' && this.htaUpdate) {
        $('#conditionDiabetes').prop('disabled', true);
      }
    },
    isDiabeteUpdate(){
      this.diabeteUpdate = sessionStorage.getItem('fds') == 'diabete' ? true : false;
      if (this.url === '/professionnel/patient_medical_form.php' && this.diabeteUpdate) {
        $('#conditionHypertension').prop('disabled', true);
      }
    },
    CanInsertPatientCroOrHcp(){
      if (this.hcp.hasOwnProperty('has_diabete')) {
        return this.hcp.has_diabete;
      }

      return true;
    },
  },

  methods: {
    emitProgramChange() {
      eventBus.$emit("program-change");
    },

    disableAndClearDiabetesVirtualStarterKit() {
      this.diabetesVirtualStarterKit =
        this.form.diabetes.insulinLessThan3Months === "0" ||
        (typeof(this.form.solutions) !== 'undefined' && this.form.solutions.includes("3")) ||
        (typeof(this.form.solutions) !== 'undefined' && this.form.solutions.includes("2"));
    },

    disableAndClearDiabetesDiabeduc() {
      this.diabetesDiabeduc = this.form.diabetes.insulinLessThan3Months === "1";
    },

    disableAndClearDiabetesMyDoseCoach() {
      this.diabetesMyDoseCoach = !(
        this.form.diabetes.type == 2 &&
        (this.form.diabetes.treatments.includes(1) ||
          this.form.diabetes.treatments.includes("1"))
      );
    },

    disableAndClearDiabetesDigitalLogbook() {
      // This option is disabled for now
      // see: https://trello.com/c/M6OklK7E/32-inscrire-un-patient-programme-support-patient
      return true || form.solutions.includes("4");
    },

    disableAndClearDiabetesContinuousGlucoseMonitoring() {
      if(typeof(this.form.solutions) !== 'undefined'){
        this.diabetesContinuousGlucoseMonitoring = this.form.solutions.includes(
          "5"
        );
      }
    },
    preChecked(){
      // this.form.solutions = [];
    },
    //For pre selecting Diab eCare program
    preCheckedProgram(){
      this.form.diabetes.programs.push(1);
      this.form.hypertension.programs.push(3);
    },

    preCheckedSolution(){
      this.form.solutions.push(7);
    },
    uncheckProgram(){
      if (this.form.diabetes.treatments.includes(2)) {
        this.form.solutions = this.form.solutions.filter(value => [6, 5, 4].includes(value));
      }
    },
    getListSolution(){
      
      this.$axios.get('/ws/get_list_solutions.php')
        .then(response => {
          this.solutionList = response.data.data;
        })
        .catch(e => {
          console.error(e);
      });
    },
    getSolutionByGroup(group,category_id){
      return this.solutionList.filter(solution => solution[group] === 1 && solution.category_id === category_id);
    },
    disableDefaultSolution(defaultSolution){
      if(this.isDisabledSolution(defaultSolution)){
        this.form.solutions = this.form.solutions.filter(value => value === defaultSolution);
      }
    },
    isDisabledSolution(id){
      const isEnabledInDB = this.solutionList.find(solution => solution.id === id).enabled;
      const conditionObj = this.disabledSolutionCondition().find(cond => cond.id === id);
      return conditionObj ? ( conditionObj.condition || !isEnabledInDB ) : !isEnabledInDB;
    },
    disabledSolutionCondition() {
        return [{
          id: 5, // Digital logbook
          condition: this.form.diabetes.treatments.includes(2) || 
          (this.form.solutions.includes('4') || this.form.solutions.includes('6')) || 
          (this.form.solutions.includes(4) || this.form.solutions.includes(6)) || 
          (this.canView.diabeteData != '' && this.canView.diabeteData)
        },
        {
          id: 6, // My Dose Coach
          condition:this.form.diabetes.treatments.includes(2) || 
          (this.form.solutions.includes('4') || this.form.solutions.includes('5')) || (this.form.solutions.includes(4) || this.form.solutions.includes(5)) || 
          ((this.form.diabetes.type === 1 || this.form.diabetes.type === 3) && (this.form.diabetes.treatments.includes(1))) || 
          (typeof(this.form.solutions) !== 'undefined' && this.form.solutions.includes('4')) || 
          ((this.form.diabetes.type === 3 || this.form.diabetes.type === 2 || this.form.diabetes.type === 1) && (this.form.diabetes.treatments.includes(2) && !this.form.diabetes.treatments.includes(1))) || 
          (this.form.diabetes.treatments.includes(2) && this.form.diabetes.treatments.includes(1) && this.form.diabetes.programs == 1) || 
          (this.form.diabetes.type === 2 && this.form.diabetes.treatments.includes(1) && this.form.diabetes.treatments.includes(2))
        },
        {
          id: 4, // Continuous Glucose Monitoring
          condition: 
          (this.form.diabetes.treatments.includes(2) || this.form.solutions.includes('6') || this.form.solutions.includes('5')) || 
          (this.form.solutions.includes(6) || this.form.solutions.includes(5)) || 
          (typeof(this.form.solutions) !== 'undefined' && this.form.solutions.includes('6')) || 
          ((this.form.diabetes.type === 3 || this.form.diabetes.type === 2 || this.form.diabetes.type === 1) && (this.form.diabetes.treatments.includes(2) && !this.form.diabetes.treatments.includes(1)))
        }
      ]},
      helperTooltip(id){
       const tooltipList = [
          {
            id: 1,
            tooltip: this.$t('_tooltip_virtual_starter_kit')
          },
          {
            id: 2,
            tooltip: this.$t('tooltip_psp_phone_email')
          },
          {
            id: 4,
            tooltip: this.$t('_tooltip_cgm')
          },
          {
            id: 5,
            tooltip: this.$t('_tooltip_digital_logbook')
          },
          {
            id: 6,
            tooltip: this.$t('_tooltip_mdc')
          },
          {
            id: 7,
            tooltip: this.$t('tooltip_hta_educ')
          }
        ];
        return tooltipList.find(tooltip => tooltip.id === id)?.tooltip ?? null;
      }
    },

  watch: {
    form: {
      handler: function(val, oldVal) {
        this.disableAndClearDiabetesVirtualStarterKit();
        this.disableAndClearDiabetesDiabeduc();
        this.disableAndClearDiabetesMyDoseCoach();
        this.disableAndClearDiabetesDigitalLogbook();
        this.disableAndClearDiabetesContinuousGlucoseMonitoring();
      },
      deep: true
    }
  },

  mounted() {
    this.preCheckedProgram();
    this.preCheckedSolution();
    this.diabetesVirtualStarterKit = this.disableAndClearDiabetesVirtualStarterKit();
    this.diabetesDiabeduc = this.disableAndClearDiabetesDiabeduc();
    this.diabetesMyDoseCoach = this.disableAndClearDiabetesMyDoseCoach();
    this.diabetesContinuousGlucoseMonitoring = this.disableAndClearDiabetesContinuousGlucoseMonitoring();
    this.isDiabeteUpdate;
    this.isHtaUpdate;
    this.getListSolution(); 
  }
};
</script>
<style scoped>
  .disabled-text {
    color: #999999;
    cursor: not-allowed; 
  }
</style>





